var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"form_provider",attrs:{"form":_vm.form,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('a-form-item',{attrs:{"label":"WhatsApp Number"}},[_c('a-row',{attrs:{"gutter":8,"type":"flex"}},[_c('a-col',{attrs:{"span":16,"order":2}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'number',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor rellena el campo',
                },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
                {
                  pattern: /^([0-9])*$/g,
                  message: 'Solo se admiten números.',
                } ],
            } ]),expression:"[\n            'number',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Por favor rellena el campo',\n                },\n                {\n                  whitespace: true,\n                  message: 'No se admiten espacios en blanco.',\n                },\n                {\n                  pattern: /^([0-9])*$/g,\n                  message: 'Solo se admiten números.',\n                },\n              ],\n            },\n          ]"}],attrs:{"maxLenght":20,"placeholder":"Escribe aquí..."}})],1),_c('a-col',{attrs:{"span":8,"order":1}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'code',
            {
              rules: [
                {
                  required: false,
                  message: 'Por favor rellena el campo',
                } ],
            } ]),expression:"[\n            'code',\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Por favor rellena el campo',\n                },\n              ],\n            },\n          ]"}],attrs:{"show-search":"","option-filter-prop":"children","placeholder":"País"}},_vm._l((_vm.countries),function(country){return _c('a-select-option',{key:country._id,attrs:{"title":country.nameSpanish}},[_vm._v(" "+_vm._s(country.dialCode)+" "+_vm._s(country.nameSpanish)+" ")])}),1)],1)],1)],1),_c('a-form-item',{attrs:{"label":"Account SID"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'wspsid',
        {
          rules: [
            {
              required: true,
              message: 'Por favor completa este campo',
            },
            {
              whitespace: true,
              message: 'No se admiten espacios en blanco.',
            } ],
        } ]),expression:"[\n        'wspsid',\n        {\n          rules: [\n            {\n              required: true,\n              message: 'Por favor completa este campo',\n            },\n            {\n              whitespace: true,\n              message: 'No se admiten espacios en blanco.',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"Escribe aquí..."}})],1),_c('a-form-item',{attrs:{"label":"Auth Token"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'token',
        {
          rules: [
            {
              required: true,
              message: 'Por favor completa este campo',
            },
            {
              whitespace: true,
              message: 'No se admiten espacios en blanco.',
            } ],
        } ]),expression:"[\n        'token',\n        {\n          rules: [\n            {\n              required: true,\n              message: 'Por favor completa este campo',\n            },\n            {\n              whitespace: true,\n              message: 'No se admiten espacios en blanco.',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"Escribe aquí..."}})],1),_c('div',{staticClass:"form_provider__footer"},[_c('a-button',{staticClass:"footer_button__default",on:{"click":function($event){return _vm.$emit('onCancel')}}},[_vm._v("Volver")]),_c('a-button',{attrs:{"html-type":"submit","type":"primary"}},[_vm._v("Integrar con Twilio")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }