var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"form",attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":("Item " + _vm.order)}},[_c('counter-text',{attrs:{"maxLength":_vm.validations.maxLengthTitleOption,"text":_vm.form.getFieldValue('title') ? _vm.form.getFieldValue('title') : ''}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'title',
          {
            rules: [
              {
                required: true,
                message: 'Por favor rellene el campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
              {
                pattern: /^([A-Za-z0-9-%ñÑ&À-ú\s])+$/g,
                message: 'No se admiten caracteres especiales',
              } ],
          } ]),expression:"[\n          'title',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor rellene el campo',\n              },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n              {\n                pattern: /^([A-Za-z0-9-%ñÑ&À-ú\\s])+$/g,\n                message: 'No se admiten caracteres especiales',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribir aquí","maxLength":_vm.validations.maxLengthTitleOption,"disabled":_vm.onlyRead},on:{"input":_vm.onInput}})],1)],1),_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Descripción"}},[_c('counter-text',{attrs:{"maxLength":_vm.validations.maxLengthDescriptionOption,"text":_vm.form.getFieldValue('description')
          ? _vm.form.getFieldValue('description')
          : ''}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'description',
          {
            rules: [
              {
                required: false,
                message: 'Por favor rellene el campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              } ],
          } ]),expression:"[\n          'description',\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Por favor rellene el campo',\n              },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribir aquí","maxLength":_vm.validations.maxLengthDescriptionOption,"disabled":_vm.onlyRead},on:{"input":_vm.onInput}})],1)],1),_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Selecciona una de las acciones"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'action',
        {
          rules: [
            {
              required: true,
              message: 'Por favor rellene el campo',
            } ],
        } ]),expression:"[\n        'action',\n        {\n          rules: [\n            {\n              required: true,\n              message: 'Por favor rellene el campo',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"Selecciona","disabled":_vm.onlyRead},on:{"change":_vm.handleChangeAction}},_vm._l((_vm.actionsToNodeSelected),function(actionToNode){return _c('a-select-option',{key:actionToNode.value,attrs:{"disabled":actionToNode.actionToSave && actionToNode.actionToSave.disabled}},[_vm._v(" "+_vm._s(actionToNode.title)+" "),(
            actionToNode.value !== _vm.action &&
            actionToNode.actionToSave &&
            actionToNode.actionToSave.disabled
          )?_c('i',[_vm._v("( No hay variables disponibles )")]):_vm._e()])}),1)],1),(_vm.action === 'transfer')?[_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Selecciona una cola"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'lineId',
          {
            rules: [{ required: true, message: 'Completa el campo' }],
          } ]),expression:"[\n          'lineId',\n          {\n            rules: [{ required: true, message: 'Completa el campo' }],\n          },\n        ]"}],attrs:{"placeholder":"Selecciona","disabled":_vm.onlyRead},on:{"change":_vm.handleChangeLines}},_vm._l((_vm.dataSourceLines),function(line){return _c('a-select-option',{key:line.key,attrs:{"value":line.key}},[_vm._v(" "+_vm._s(line.title)+" ")])}),1)],1),_c('a-switch',{staticClass:"form__switch",attrs:{"size":"small","disabled":_vm.onlyRead},on:{"change":function($event){return _vm.setIsSavedItem(false)}},model:{value:(_vm.activeMessageTransfer),callback:function ($$v) {_vm.activeMessageTransfer=$$v},expression:"activeMessageTransfer"}}),_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Mensaje de transferencia"}},[_c('counter-text',{attrs:{"maxLength":350,"text":_vm.form.getFieldValue('message') ? _vm.form.getFieldValue('message') : ''}},[_c('a-mentions',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'message',
            {
              initialValue:
                'En un momento, uno de nuestros agentes lo estará atendiendo',
              rules: [
                {
                  required: _vm.activeMessageTransfer,
                  message: 'Por favor rellene el campo',
                },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                } ],
            } ]),expression:"[\n            'message',\n            {\n              initialValue:\n                'En un momento, uno de nuestros agentes lo estará atendiendo',\n              rules: [\n                {\n                  required: activeMessageTransfer,\n                  message: 'Por favor rellene el campo',\n                },\n                {\n                  whitespace: true,\n                  message: 'No se admiten espacios en blanco.',\n                },\n              ],\n            },\n          ]"}],ref:"message_form_text",staticClass:"text--left",attrs:{"rows":"3","placeholder":"Escribe aquí...","maxLength":350,"prefix":_vm.PREFIX_VARS,"notFoundContent":_vm.notFoundVars,"placement":"bottom","disabled":!_vm.activeMessageTransfer || _vm.onlyRead,"filterOption":_vm.filterOption},on:{"change":_vm.handleChangeText}},_vm._l((_vm.simpleVars),function(simpleVar){return _c('a-mentions-option',{key:simpleVar._id,attrs:{"value":simpleVar.name}},[_vm._v(" "+_vm._s(simpleVar.name))])}),1)],1),_c('menu-options-content',{attrs:{"show-wait":false,"disabled-emoji":!_vm.activeMessageTransfer || _vm.onlyRead,"disabled-vars":!_vm.activeMessageTransfer || _vm.onlyRead},on:{"onChangeEmoji":_vm.handleSetEmoji,"onOpenVars":_vm.handleOpenVars}})],1)]:(_vm.action === 'none')?[_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Mensaje final"}},[_c('p',{staticClass:"help mrg-bottom-8 text--left",staticStyle:{"margin-top":"4px"}},[_vm._v(" Recuerda que este mensaje se mostrará al cliente cuando elija esta opción. ")]),_c('counter-text',{attrs:{"maxLength":350,"text":_vm.form.getFieldValue('message') ? _vm.form.getFieldValue('message') : ''}},[_c('a-mentions',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'message',
            {
              initialValue:
                'Actualmente no atendemos por este medio, gracias.',
              rules: [
                {
                  required: false,
                  message: 'Por favor rellene el campo',
                },
                {
                  whitespace: false,
                  message: 'No se admiten espacios en blanco.',
                } ],
            } ]),expression:"[\n            'message',\n            {\n              initialValue:\n                'Actualmente no atendemos por este medio, gracias.',\n              rules: [\n                {\n                  required: false,\n                  message: 'Por favor rellene el campo',\n                },\n                {\n                  whitespace: false,\n                  message: 'No se admiten espacios en blanco.',\n                },\n              ],\n            },\n          ]"}],ref:"message_form_text",staticClass:"text--left",attrs:{"rows":"3","placeholder":"Escribe aquí...","maxLength":350,"prefix":_vm.PREFIX_VARS,"notFoundContent":_vm.notFoundVars,"placement":"bottom","disabled":_vm.onlyRead,"filterOption":_vm.filterOption},on:{"change":_vm.handleChangeText}},_vm._l((_vm.simpleVars),function(simpleVar){return _c('a-mentions-option',{key:simpleVar._id,attrs:{"value":simpleVar.name}},[_vm._v(" "+_vm._s(simpleVar.name))])}),1)],1),_c('menu-options-content',{attrs:{"show-wait":false,"disabled-emoji":_vm.onlyRead,"disabled-vars":_vm.onlyRead},on:{"onChangeEmoji":_vm.handleSetEmoji,"onOpenVars":_vm.handleOpenVars}})],1)]:_vm._e(),(_vm.action === 'rollback')?[_c('p',{staticClass:"body-2 text--left"},[_vm._v(" Recuerda que se volverán a mostrar las opciones del menú anterior. ")])]:(_vm.description)?[_c('p',{staticClass:"body-2 text--left"},[_vm._v(" "+_vm._s(_vm.description)+" ")])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }