var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-form-item',{staticClass:"section-form-item section-form-item--message",attrs:{"label":"Texto / Pregunta"}},[_c('p',{staticClass:"help text--left mrg-bottom-8"},[_vm._v(" Recuerda que será un mensaje de tipo texto para tus usuarios. ")]),_c('counter-text',{attrs:{"maxLength":_vm.validations.maxLengthMessage,"text":_vm.form.getFieldValue('content') ? _vm.form.getFieldValue('content') : ''}},[_c('a-mentions',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'content',
          {
            rules: [
              {
                required: true,
                message: 'Por favor rellene el campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              } ],
          } ]),expression:"[\n          'content',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor rellene el campo',\n              },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],ref:"message_form_text",staticClass:"text--left",attrs:{"rows":"3","placeholder":"Escribe aquí...","maxLength":_vm.validations.maxLengthMessage,"prefix":_vm.PREFIX_VARS,"notFoundContent":_vm.notFoundVars,"disabled":_vm.onlyRead,"filterOption":_vm.filterOption,"placement":"bottom"},on:{"input":_vm.onInput,"change":function($event){return _vm.setIsSavedItem(false)}}},_vm._l((_vm.simpleVars),function(simpleVar){return _c('a-mentions-option',{key:simpleVar._id,attrs:{"value":simpleVar.name}},[_vm._v(" "+_vm._s(simpleVar.name))])}),1)],1),_c('menu-options-content',{attrs:{"disabled-emoji":_vm.onlyRead},on:{"onChangeWait":_vm.handleChangeWait,"onChangeEmoji":_vm.handleSetEmoji,"onOpenVars":_vm.handleOpenVars}})],1),_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Guarda la respuesta en una variable"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'varToSave',
        {
          rules: [
            {
              required: true,
              message: 'Por favor rellene el campo',
            } ],
        } ]),expression:"[\n        'varToSave',\n        {\n          rules: [\n            {\n              required: true,\n              message: 'Por favor rellene el campo',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"Selecciona","disabled":_vm.onlyRead},on:{"change":function($event){return _vm.setIsSavedItem(false)}}},_vm._l((_vm.simpleVarsForSave),function(simpleVar){return _c('a-select-option',{key:simpleVar._id,attrs:{"disabled":(simpleVar.isUsed && simpleVar._id !== _vm.varToSave._id) ||
          simpleVar.deleted,"title":_vm.formatNameSimpleVar(simpleVar)}},[_vm._v(" "+_vm._s(simpleVar.name)+" "),(simpleVar.deleted)?_c('i',[_vm._v("( Variable eliminada )")]):(simpleVar.isUsed && simpleVar._id !== _vm.varToSave._id)?_c('i',[_vm._v("( Usado en otro nodo )")]):_vm._e()])}),1),_c('p',{staticClass:"text--left regular-14 mrg-bottom-0",attrs:{"slot":"extra"},slot:"extra"},[_vm._v(" Puedes crear tus propias variables. "),_c('router-link',{attrs:{"to":{ name: 'manage-chatbots-vars' },"target":"_blank"}},[_vm._v("Click aquí.")])],1)],1),_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Selecciona una de las acciones"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'action',
        {
          rules: [
            {
              required: true,
              message: 'Por favor rellene el campo',
            } ],
        } ]),expression:"[\n        'action',\n        {\n          rules: [\n            {\n              required: true,\n              message: 'Por favor rellene el campo',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"Selecciona","disabled":_vm.onlyRead},on:{"change":_vm.handleChangeAction}},_vm._l((_vm.actionsToNodeSelected),function(actionToNode){return _c('a-select-option',{key:actionToNode.value,attrs:{"disabled":actionToNode.actionToSave && actionToNode.actionToSave.disabled}},[_vm._v(" "+_vm._s(actionToNode.title)+" "),(
            actionToNode.value !== _vm.action &&
            actionToNode.actionToSave &&
            actionToNode.actionToSave.disabled
          )?_c('i',[_vm._v("( No hay variables disponibles )")]):_vm._e()])}),1)],1),(_vm.action === 'transfer')?_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Selecciona una cola"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'lineId',
        {
          rules: [{ required: true, message: 'Completa el campo' }],
        } ]),expression:"[\n        'lineId',\n        {\n          rules: [{ required: true, message: 'Completa el campo' }],\n        },\n      ]"}],attrs:{"placeholder":"Selecciona","disabled":_vm.onlyRead},on:{"change":_vm.handleChangeLines}},_vm._l((_vm.dataSourceLines),function(line){return _c('a-select-option',{key:line.key,attrs:{"value":line.key}},[_vm._v(" "+_vm._s(line.title)+" ")])}),1)],1):_vm._e(),(_vm.description)?[_c('p',{staticClass:"body-2 text--left"},[_vm._v(" "+_vm._s(_vm.description)+" ")])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }