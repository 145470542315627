var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"custom-field"},[_c('div',{staticClass:"custom-field__content"},[_c('a-select',{staticClass:"filter-item__select",attrs:{"default-value":_vm.field.customFieldId},on:{"change":_vm.onCustomFieldChange}},_vm._l((_vm.listTypeFields),function(item){return _c('a-select-opt-group',{key:item.type},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('a-icon',{staticClass:"mrg-right-8",attrs:{"type":item.typeIcon}}),_vm._v(_vm._s(item.title))],1),_vm._l((_vm.filterTypes(item.type)),function(custom){return _c('a-select-option',{key:custom._id,attrs:{"value":custom._id,"disabled":custom.disabled}},[_vm._v(" "+_vm._s(custom.name)+" ")])})],2)}),1),_c('p',{staticClass:"regular-12 custom-field__text-middle"},[_vm._v("Es:")]),_c('a-form-item',[(_vm.customField.type == 'dropdown')?_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            _vm.field.customFieldId,
            {
              rules: _vm.rules.date,
            } ]),expression:"[\n            field.customFieldId,\n            {\n              rules: rules.date,\n            },\n          ]"}],staticClass:"filter-item__select",attrs:{"mode":_vm.customField.multiple ? 'multiple' : '',"placeholder":"Selecciona"},on:{"change":function($event){return _vm.onChangeValue($event, 'dropdown')}}},_vm._l((_vm.customField.options),function(option){return _c('a-select-option',{key:option.idOption,attrs:{"value":option.idOption,"title":option.name}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1):(_vm.customField.type == 'text')?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            _vm.field.customFieldId,
            {
              rules: _vm.rules.default,
            } ]),expression:"[\n            field.customFieldId,\n            {\n              rules: rules.default,\n            },\n          ]"}],attrs:{"placeholder":"Escribe aquí..."},on:{"input":function($event){return _vm.onChangeValue($event.target.value)}}}):(_vm.customField.type == 'date')?[(_vm.hasRange)?_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              _vm.field.customFieldId,
              {
                rules: _vm.rules.date,
              } ]),expression:"[\n              field.customFieldId,\n              {\n                rules: rules.date,\n              },\n            ]"}],attrs:{"allowClear":false},on:{"change":_vm.onChangeValue}}):_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              _vm.field.customFieldId,
              {
                rules: _vm.rules.date,
              } ]),expression:"[\n              field.customFieldId,\n              {\n                rules: rules.date,\n              },\n            ]"}],staticClass:"full-width",attrs:{"placeholder":"2020-05-25","allowClear":false},on:{"change":_vm.onChangeValue}})]:_vm._e()],2)],1),_c('div',{staticClass:"custom-field__remove"},[_c('a-icon',{staticStyle:{},attrs:{"type":"close"},on:{"click":function($event){return _vm.$emit('onRemove')}}})],1)]),_c('a-divider',{staticClass:"custom-field__divider",class:{ 'display-none': _vm.isLast }})],1)}
var staticRenderFns = []

export { render, staticRenderFns }