var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-custom-drawer',{attrs:{"title":"Comentarios","showBack":_vm.showBack,"textBack":"Cerrar"},on:{"onBack":function($event){return _vm.$emit('onClose')}}},[_c('div',{staticClass:"section__content-input"},[_c('a-input-search',{attrs:{"placeholder":"Buscar por palabra"},on:{"search":_vm.handleSearch},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),_c('a-row',{staticClass:"flex-no-wrap direction-column section__container",class:{ 'section__container-history': _vm.target.includes('history') },attrs:{"type":"flex"}},[_c('custom-section-scroll',{attrs:{"master-styles":{ flexGrow: 1, overflow: 'auto' },"scroll-pagination":{
        observedElement: 'preview-comment--last',
        isLoading: _vm.loading || _vm.filters.loading,
        showEmpty: !_vm.paginate_comments_ticket.length,
        onNext: _vm.searchNextPage,
      }}},[_c('div',{staticClass:"section__content"},_vm._l((_vm.paginate_comments_ticket),function(comment,index){return _c('comment',{key:comment._id,class:{
            'preview-comment--last':
              index === _vm.paginate_comments_ticket.length - 1,
          },attrs:{"full-name":_vm.fullName(Object.assign({}, comment.user)),"comment":comment.text,"avatar":comment.user.avatar,"userId":comment.user._id,"date":comment.created_at,"messageId":comment.messageId,"is-linked":!!comment.messageId,"commentId":comment._id,"type":_vm.target.includes('history') ? 'history' : 'default'},nativeOn:{"click":function($event){!!comment.messageId &&
              _vm.handleSelectLinkedComment(comment._id, comment.messageId)}}})}),1)]),(_vm.showInputComment)?_c('input-comment'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }