var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"display-flex direction-row flex-wrap"},[_vm._l((_vm.colors),function(color){return _c('color-pick',{key:color,attrs:{"color":color,"selectable":true,"size":"custom","shape":"round","has-check":true,"is-selected":_vm.localColorSelected === color},on:{"onChange":_vm.handleChangeColor}})}),_c('a-form',{staticClass:"picker-custom",attrs:{"form":_vm.form}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'codeColor',
          {
            rules: [
              {
                pattern: /^(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$/gm,
                message: 'Debe ser un color hexadecimal.',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
              {
                pattern: /[^[F|f]{3,6}$/gm,
                message: 'No se acepta el color blanco.',
              } ],
          } ]),expression:"[\n          'codeColor',\n          {\n            rules: [\n              {\n                pattern: /^(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$/gm,\n                message: 'Debe ser un color hexadecimal.',\n              },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n              {\n                pattern: /[^[F|f]{3,6}$/gm,\n                message: 'No se acepta el color blanco.',\n              },\n            ],\n          },\n        ]"}],staticClass:"picker-custom__input",attrs:{"prefix":"#","placeholder":"E8E8E8","maxLength":6},on:{"input":function($event){return _vm.handleChangeCustomColor(("#" + ($event.target.value)))}}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }