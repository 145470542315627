var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-form-item',{attrs:{"label":"Nombre de chatbot"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'name',
        {
          rules: [
            { required: true, message: 'Por favor rellene el campo' },
            {
              whitespace: true,
              message: 'No se admiten espacios en blanco.',
            } ],
        } ]),expression:"[\n        'name',\n        {\n          rules: [\n            { required: true, message: 'Por favor rellene el campo' },\n            {\n              whitespace: true,\n              message: 'No se admiten espacios en blanco.',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"Escribe aquí...","maxLength":70}})],1),_c('a-form-item',{attrs:{"label":"Descripción de chatbot"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'description',
        {
          rules: [
            { required: false, message: 'Por favor rellene el campo' },
            {
              whitespace: true,
              message: 'No se admiten espacios en blanco.',
            } ],
        } ]),expression:"[\n        'description',\n        {\n          rules: [\n            { required: false, message: 'Por favor rellene el campo' },\n            {\n              whitespace: true,\n              message: 'No se admiten espacios en blanco.',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"Escribe aquí...","auto-size":{ minRows: 3, maxRows: 5 },"maxLength":_vm.maxDescription},on:{"input":_vm.handleInput}}),_c('span',{staticClass:"counter"},[_vm._v(_vm._s(_vm.currentDescription ? _vm.currentDescription.length : 0)+"/"+_vm._s(_vm.maxDescription))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }