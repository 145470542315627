var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.title,"centered":""},on:{"cancel":_vm.handleCancel}},[_c('div',[_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('p',{staticClass:"regular-14 form__description"},[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c('a-form-item',{attrs:{"label":"Selecciona el rango de fecha"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'dateRange',
            {
              initialValue: [
                _vm.moment(((_vm.moment().format('L')) + " 00:00"), 'D/M/YYYY HH:mm'),
                _vm.moment().endOf('day') ],
              rules: [
                {
                  required: true,
                  message: 'Por favor rellena el campo',
                },
                { validator: _vm.validateMaxMonths } ],
            } ]),expression:"[\n            'dateRange',\n            {\n              initialValue: [\n                moment(`${moment().format('L')} 00:00`, 'D/M/YYYY HH:mm'),\n                moment().endOf('day'),\n              ],\n              rules: [\n                {\n                  required: true,\n                  message: 'Por favor rellena el campo',\n                },\n                { validator: validateMaxMonths },\n              ],\n            },\n          ]"}],staticClass:"form__item",staticStyle:{"width":"100%"},attrs:{"disabled-date":_vm.disabledDateAfterCurrent,"show-time":{
            format: _vm.localePicker.lang.dateTimeFormat,
            defaultValue: [
              _vm.moment('00:00:00', 'HH:mm:ss'),
              _vm.moment('23:59:59', 'HH:mm:ss') ],
          },"placeholder":['Fecha de inicio', 'Fecha de fin'],"format":_vm.localePicker.lang.dateTimeFormat,"locale":_vm.localePicker}})],1)],1),_c('basic-footer',{attrs:{"slot":"footer","cancelText":"Cancelar","okText":_vm.okText,"okLoading":_vm.okLoading,"cancelDisabled":_vm.disabledActions,"okDisabled":_vm.disabledActions},on:{"onOk":_vm.handleOk,"onCancel":_vm.handleCancel},slot:"footer"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }