var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-drawer',{attrs:{"title":_vm.drawer_filters.title,"width":_vm.drawer_filters.width,"visible":_vm.visible},on:{"close":_vm.onClose}},[_c('a-form',{staticClass:"filters",attrs:{"form":_vm.form,"layout":"vertical","hide-required-mark":""}},[_c('a-form-item',{staticClass:"filters__item",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"semibold-14"},[_vm._v("Estados")])]},proxy:true}])},[_c('a-select',{staticClass:"section__button",attrs:{"placeholder":"Selecciona"},model:{value:(_vm.localFilters.status),callback:function ($$v) {_vm.$set(_vm.localFilters, "status", $$v)},expression:"localFilters.status"}},[_c('a-select-option',{attrs:{"value":"all"}},[_vm._v("Todos los estados")]),_c('a-select-option',{attrs:{"value":"enable"}},[_vm._v("Activos")]),_c('a-select-option',{attrs:{"value":"disable"}},[_vm._v("Inactivos")])],1)],1),_c('a-form-item',{staticClass:"filters__item"},[_c('h6',{staticClass:"semibold-14"},[_vm._v("Campos personalizados")]),(
          !_vm.fields_company_products || _vm.fields_company_products.length === 0
        )?[_c('p',{staticClass:"empty"},[_vm._v("No hay campos personalizados")])]:_vm._e(),(
          _vm.localFilters.customFields && _vm.localFilters.customFields.length > 0
        )?[_vm._l((_vm.localFilters.customFields),function(customField,index){return [_c('custom-field-option',{key:customField.key,attrs:{"field":customField,"data-source":_vm.disabledFields(_vm.fields_company_products),"isLast":_vm.localFilters.customFields.length - 1 === index},on:{"onRemove":function($event){return _vm.removeCustomFilter(customField.key)},"onChangeValue":_vm.updateCustomFilterValue,"onChangeField":_vm.updateCustomFilter}})]})]:_vm._e(),(_vm.fields_company_products && _vm.fields_company_products.length > 0)?[_c('a',{staticClass:"add",on:{"click":_vm.addCustomFieldFilter}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" Agregar campo customizado ")],1)]:_vm._e()],2)],1),_c('div',{style:({
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 1,
    })},[_c('a-button',{style:({ marginRight: '8px' }),attrs:{"disabled":_vm.disabledCancel}},[_vm._v(" Cancelar ")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loadingOk,"disabled":_vm.disabledOk},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.okText)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }