var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Mensaje de botones"}},[_c('p',{staticClass:"help text--left mrg-bottom-8"},[_vm._v(" Recuerda que sera un mensaje de introducción para tus usuarios. ")]),_c('counter-text',{attrs:{"maxLength":_vm.validations.maxLengthMessage,"text":_vm.form.getFieldValue('content') ? _vm.form.getFieldValue('content') : ''}},[_c('a-mentions',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'content',
          {
            rules: [
              {
                required: true,
                message: 'Por favor rellene el campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              } ],
          } ]),expression:"[\n          'content',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor rellene el campo',\n              },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],ref:"content",staticClass:"text--left",attrs:{"rows":"3","placeholder":"Escribe aquí...","maxLength":_vm.validations.maxLengthMessage,"prefix":_vm.PREFIX_VARS,"notFoundContent":_vm.notFoundVars,"placement":"bottom","filterOption":_vm.filterOption,"disabled":_vm.onlyRead},on:{"change":function($event){return _vm.setIsSavedItem(false)}}},_vm._l((_vm.simpleVars),function(simpleVar){return _c('a-mentions-option',{key:simpleVar._id,attrs:{"value":simpleVar.name}},[_vm._v(" "+_vm._s(simpleVar.name))])}),1)],1),_c('menu-options-content',{attrs:{"show-wait":false,"disabled-emoji":_vm.onlyRead},on:{"onChangeEmoji":function (emoji) { return _vm.handleSetEmoji(emoji, 'content'); },"onOpenVars":function($event){return _vm.handleOpenVars('content')}}})],1),_c('a-form-item',{staticClass:"section-form-item"},[_c('template',{slot:"label"},[_c('span',{staticClass:"mrg-right-8"},[_vm._v(" Guarda la selección de un Botón en una variable ")]),_c('a-switch',{staticClass:"form__switch",attrs:{"size":"small"},on:{"change":function($event){return _vm.setIsSavedItem(false)}},model:{value:(_vm.checkedVarToSave),callback:function ($$v) {_vm.checkedVarToSave=$$v},expression:"checkedVarToSave"}})],1),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'varToSave',
        {
          rules: [
            {
              required: _vm.checkedVarToSave,
              message: 'Por favor rellene el campo',
            } ],
        } ]),expression:"[\n        'varToSave',\n        {\n          rules: [\n            {\n              required: checkedVarToSave,\n              message: 'Por favor rellene el campo',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"Selecciona una de las variables","disabled":_vm.onlyRead || !_vm.checkedVarToSave},on:{"change":function($event){return _vm.setIsSavedItem(false)}}},_vm._l((_vm.simpleVarsForSave),function(simpleVar){return _c('a-select-option',{key:simpleVar._id,attrs:{"disabled":(simpleVar.isUsed && simpleVar._id !== _vm.varToSave._id) ||
          simpleVar.deleted,"title":_vm.formatNameSimpleVar(simpleVar)}},[_vm._v(" "+_vm._s(simpleVar.name)+" "),(simpleVar.deleted)?_c('i',[_vm._v("( Variable eliminada )")]):(simpleVar.isUsed && simpleVar._id !== _vm.varToSave._id)?_c('i',[_vm._v("( Usado en otro nodo )")]):_vm._e()])}),1),_c('p',{staticClass:"text--left regular-14 mrg-bottom-0",attrs:{"slot":"extra"},slot:"extra"},[_vm._v(" Puedes crear tus propias variables. "),_c('router-link',{attrs:{"to":{ name: 'manage-chatbots-vars' },"target":"_blank"}},[_vm._v("Click aquí.")])],1)],2),_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Mensaje por respuesta incorrecta"}},[_c('p',{staticClass:"help",staticStyle:{"margin-bottom":"8px","text-align":"left"}},[_vm._v(" Recuerda este mensaje se mostrará al cliente cuando mande un nuevo mensaje y no seleccione ninguno de los botones mostrados. ")]),_c('counter-text',{attrs:{"maxLength":_vm.validations.maxLengthErrorMessage,"text":_vm.form.getFieldValue('errorMessage')
          ? _vm.form.getFieldValue('errorMessage')
          : ''}},[_c('a-mentions',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'errorMessage',
          {
            rules: [
              {
                required: true,
                message: 'Por favor rellene el campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              } ],
          } ]),expression:"[\n          'errorMessage',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor rellene el campo',\n              },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],ref:"errorMessage",staticClass:"text--left",attrs:{"rows":"3","placeholder":"Escribe aquí...","maxLength":_vm.validations.maxLengthErrorMessage,"prefix":_vm.PREFIX_VARS,"notFoundContent":_vm.notFoundVars,"placement":"bottom","filterOption":_vm.filterOption,"disabled":_vm.onlyRead},on:{"change":function($event){return _vm.setIsSavedItem(false)}}},_vm._l((_vm.simpleVars),function(simpleVar){return _c('a-mentions-option',{key:simpleVar._id,attrs:{"value":simpleVar.name},on:{"onOpenVars":function($event){return _vm.handleOpenVars('errorMessage')}}},[_vm._v(" "+_vm._s(simpleVar.name))])}),1)],1),_c('menu-options-content',{attrs:{"show-wait":false,"disabled-emoji":_vm.onlyRead},on:{"onChangeEmoji":function (emoji) { return _vm.handleSetEmoji(emoji, 'errorMessage'); },"onOpenVars":_vm.handleOpenVars}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }