var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"sidebar",class:{
    'sidebar--collapse': _vm.menuCollapse,
  },on:{"mouseleave":_vm.handleLeaveCollapse,"mouseenter":_vm.handleSetIsInSidebar}},[_c('div',{staticClass:"sidebar__content"},[_c('div',{staticClass:"sidebar__main",on:{"mouseenter":function($event){return _vm.handleEnterSidebar(true)}}},[_c('logo',{staticClass:"sidebar__logo",attrs:{"app":_vm.app,"type":"light","size":_vm.sizeLogo}}),_c('div',{staticClass:"sidebar__menu-content scrollbar_basic"},[_vm._l((_vm.menuFormatted),function(menuItem){return [(menuItem.menuItems)?[_c('sub-menu-item',{key:menuItem._id,attrs:{"title":menuItem.title,"show-title":_vm.menuCollapse,"active":_vm.isActiveSubMenu(menuItem.menuItems, menuItem._id),"selected":_vm.isActiveSubMenu(menuItem.menuItems, menuItem._id) && !_vm.idMenu
                  ? _vm.selectSubMenu(menuItem._id)
                  : _vm.idMenu === menuItem._id,"width":"inherit","disabled":_vm.profileRemote ? true : false,"icon":menuItem.icon,"force-collapse":_vm.isActiveSubMenu(menuItem.menuItems, menuItem._id) &&
                _vm.menuCollapse,"is-new":menuItem.new},on:{"onSelected":function($event){return _vm.selectSubMenu(menuItem._id)}}},[_c('template',{slot:"items"},_vm._l((menuItem.menuItems),function(item){return _c('menu-item',{key:item._id,attrs:{"icon":item.svg,"show-title":_vm.menuCollapse,"route":item.route,"show-mark":false,"width":"inherit","disabled":_vm.profileRemote ? true : false,"is-new":menuItem.new},on:{"onSelected":function($event){return _vm.selectMenu(item)}}},[_c('p',{staticClass:"heading-h7 mrg-bottom-0 ellipsis",attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(item.title)+" ")])])}),1)],2)]:_c('menu-item',{key:menuItem._id,attrs:{"title":menuItem.title,"icon":menuItem.svg,"show-title":_vm.menuCollapse,"route":menuItem.route,"badge":_vm.menuBadges.find(function (menu) { return menu.route === menuItem.route; }) !==
              undefined
                ? _vm.menuBadges.find(function (menu) { return menu.route === menuItem.route; })
                    .badge
                : false,"width":"inherit","disabled":_vm.profileRemote ? true : false,"is-new":menuItem.new},on:{"onSelected":function($event){return _vm.selectSubMenu(menuItem._id)}}})]})],2)],1),_c('div',[(_vm.showMenuNotifications)?_c('menu-notifications',{attrs:{"disabled":_vm.profileRemote ? true : false,"show-popup":_vm.showPopupFooter}}):_vm._e(),(_vm.showMenuCompanies)?_c('menu-companies',{attrs:{"collapse":_vm.menuCollapse,"company-name":_vm.company.name,"disabled":_vm.profileRemote ? true : false,"show-popup":_vm.showPopupFooter}}):_vm._e(),(_vm.profile && _vm.company && _vm.companyPricing)?_c('menu-footer',{attrs:{"collapse":_vm.menuCollapse,"names":_vm.fullname,"email":_vm.profile.email,"phone-number":_vm.phoneNumber,"avatar":_vm.profile.avatar,"company-name":_vm.company.name,"banner":_vm.company.banner,"disabled":_vm.profileRemote ? true : false,"show-popup":_vm.showPopupFooter,"type-user":_vm.transformTypeUser(_vm.profile.type, _vm.profile.subType || ''),"plan-name":_vm.namePlan}}):_vm._e()],1)]),_c('div',{class:{
      'sidebar-button--active': _vm.menuCollapse,
      'sidebar-button--inactive': !_vm.menuCollapse,
    },on:{"click":function($event){return _vm.handleClickButton(!_vm.menuCollapse)}}})])}
var staticRenderFns = []

export { render, staticRenderFns }