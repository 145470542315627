var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",class:{
    'card--selected': _vm.isSelected,
    'card--selected--disabled': _vm.disabled && _vm.isSelected,
    'card--disabled': _vm.disabled && !_vm.isSelected,
  },on:{"click":_vm.handleChange}},[_c('div',{staticClass:"card__circle",class:{
      'circle-bottom--right': _vm.position === 'bottomRight',
      'circle-bottom--left': _vm.position === 'bottomLeft',
      'circle-top--left': _vm.position === 'topLeft',
      'circle-top--right': _vm.position === 'topRight',
    }})])}
var staticRenderFns = []

export { render, staticRenderFns }