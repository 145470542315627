var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-list-supervision"},[_c('a-table',{staticClass:"scrollbar_basic table-supervision",staticStyle:{"border-bottom":"1px solid rgba(0, 0, 0, 0.1)"},attrs:{"data-source":_vm.newData,"pagination":false,"loading":_vm.sectionSupervision.list.loading.table,"locale":_vm.localeTable,"scroll":{ y: 'calc(100vh - 168px)' },"size":"middle"}},[_c('a-table-column',{key:"number",attrs:{"title":"N°","data-index":"number","width":"5%"},scopedSlots:_vm._u([{key:"default",fn:function(number){return [_c('span',[_vm._v(_vm._s(number))])]}}])}),_c('a-table-column',{key:"agent",attrs:{"title":"Agente","data-index":"agent"},scopedSlots:_vm._u([{key:"default",fn:function(agent){return [_c('div',{staticStyle:{"display":"flex"}},[_c('StatusAvatar',{attrs:{"size":40,"status":agent.status,"component":'List',"info":agent}}),_c('div',[_c('a',{staticClass:"ellipsis mrg-bottom-0 table__names",attrs:{"disabled":agent.subType && agent.subType === 'chatbot'},on:{"click":function($event){return _vm.handleOpenDrawer(agent)}}},[_vm._v(_vm._s(agent.names))]),_c('div',[_c('span',{staticClass:"body-1",staticStyle:{"color":"#686a6f"}},[_vm._v("En "+_vm._s(agent.status === 'connected' ? agent.menu : _vm.transformStatus(agent.status)))]),(
                  agent.status !== 'disconnected' &&
                  agent.subType !== 'chatbot'
                )?_c('a-divider',{attrs:{"type":"vertical"}}):_vm._e(),(
                  agent.status !== 'disconnected' &&
                  agent.subType !== 'chatbot'
                )?_c('span',[(agent.isTyping)?_c('WaitPointsAnimation',[[_c('span',{staticClass:"body-1",staticStyle:{"color":"#686a6f"}},[_vm._v("Escribiendo")])]],2):[_c('span',{staticClass:"body-1",staticStyle:{"color":"#686a6f"}},[_vm._v(_vm._s(agent.time))])]],2):_vm._e()],1)])],1)]}}])}),_c('a-table-column',{key:"pendings",attrs:{"title":"Tickets sin leer","data-index":"pendings","width":"16%"},scopedSlots:_vm._u([{key:"default",fn:function(pendings){return [_c('span',{staticClass:"gray_dark_900"},[_vm._v(_vm._s(pendings))])]}}])}),_c('a-table-column',{key:"ticketsAssigned",attrs:{"title":"Tickets activos","data-index":"ticketsAssigned","width":"16%"},scopedSlots:_vm._u([{key:"default",fn:function(ticketsAssigned){return [_c('span',{staticClass:"gray_dark_900"},[_vm._v(_vm._s(ticketsAssigned))])]}}])}),_c('a-table-column',{key:"event",attrs:{"title":"Evento","data-index":"event"},scopedSlots:_vm._u([{key:"default",fn:function(event){return [(event.value)?_c('div',{staticClass:"display-flex align--center"},[_c('social-icon',{attrs:{"type":event.value}}),_c('div',{staticClass:"mrg-left-8"},[_c('p',{staticClass:"body-2 ellipsis mrg-bottom-0 gray_dark_900"},[_vm._v(" "+_vm._s(event.client ? event.client.names : event.client.phone)+" ")]),_c('span',{staticClass:"gray_dark_900"},[_vm._v(_vm._s(event.time))])])],1):_vm._e()]}}])}),_c('a-table-column',{key:"action",attrs:{"title":"Acciones","width":200},scopedSlots:_vm._u([{key:"default",fn:function(text, record){return [_c('span',[_c('a',{on:{"click":function($event){return _vm.handleView(record)}}},[_vm._v("Visualizar")]),(record.agent.subType !== 'chatbot')?[_c('a-divider',{attrs:{"type":"vertical"}}),(
                (record.agent.status === 'disconnected' &&
                  record.agent.session === false) ||
                _vm.isAllowedFor(record.agent.type, ['admin'])
              )?_c('a',{attrs:{"disabled":(record.agent.status === 'disconnected' &&
                  record.agent.session === false) ||
                _vm.isAllowedFor(record.agent.type, ['admin']),"aria-disabled":true}},[_vm._v("Cerrar sesión")]):_c('a',{on:{"click":function($event){return _vm.handleCloseSesion(record)}}},[_vm._v("Cerrar sesión")])]:_vm._e()],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }