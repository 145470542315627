var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.title,"centered":""},on:{"cancel":_vm.handleCancel}},[_c('p',[_vm._v(" Después de bloquear un número telefónico, tu empresa no volverá a recibir mensajes de este. No se notificará del bloqueo al propietario del teléfono. ")]),_c('a-form',{attrs:{"layout":"vertical","form":_vm.form}},[_vm._v(" "+_vm._s(_vm.validateNewValuesInDom())+" "),_c('a-form-item',{attrs:{"label":"ID de canal"}},[_c('p',{staticClass:"regular-14 mrg-bottom-8"},[_vm._v(" Selecciona los canales de WhatsApp por los que no quieres recibir mensajes del número a bloquear. ")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'channelsId',
          {
            rules: [{ required: true, message: 'Este campo es requerido' }],
          } ]),expression:"[\n          'channelsId',\n          {\n            rules: [{ required: true, message: 'Este campo es requerido' }],\n          },\n        ]"}],attrs:{"placeholder":"Selecciona los ID de WhatsApp","mode":"multiple","filter-option":true,"option-filter-prop":"title","show-search":"","notFoundContent":"No se encontraron ID de canales de WhatsApp"}},_vm._l((_vm.simpleChannelsFiltered),function(simpleChannel){return _c('a-select-option',{key:simpleChannel._id,attrs:{"title":simpleChannel.title}},[_c('a-row',{attrs:{"type":"flex","align":"middle"}},[(simpleChannel.channel)?_c('social-icon',{staticClass:"mrg-right-4",attrs:{"type":simpleChannel.channel,"size":14,"disabled":true}}):_vm._e(),_c('span',{class:{ 'mrg-right-4': simpleChannel.alias }},[_vm._v(_vm._s(simpleChannel.title)+" ")]),_c('span',[_vm._v(" "+_vm._s(simpleChannel.alias ? ("(" + (simpleChannel.alias) + ")") : ''))])],1)],1)}),1)],1),_c('a-form-item',{attrs:{"label":"Número"}},[_c('p',{staticClass:"regular-14 mrg-bottom-8"},[_vm._v(" Recuerda que el número debe estar registrado como contacto en "+_vm._s(_vm.app)+" para ser bloqueado. ")]),_c('a-row',{attrs:{"gutter":8,"type":"flex"}},[_c('a-col',{attrs:{"span":16,"order":2}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'number',
              {
                rules: [
                  {
                    required: true,
                    message: 'Por favor rellena el campo',
                  },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                  {
                    pattern: /^([0-9])*$/g,
                    message: 'Solo se admiten números.',
                  } ],
              } ]),expression:"[\n              'number',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Por favor rellena el campo',\n                  },\n                  {\n                    whitespace: true,\n                    message: 'No se admiten espacios en blanco.',\n                  },\n                  {\n                    pattern: /^([0-9])*$/g,\n                    message: 'Solo se admiten números.',\n                  },\n                ],\n              },\n            ]"}],attrs:{"maxLength":20,"placeholder":"987654321","disabled":_vm.disabledSomeFields}})],1),_c('a-col',{attrs:{"span":8,"order":1}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'countryId',
              {
                rules: [
                  {
                    required: false,
                    message: 'Por favor rellena el campo',
                  } ],
              } ]),expression:"[\n              'countryId',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'Por favor rellena el campo',\n                  },\n                ],\n              },\n            ]"}],attrs:{"show-search":"","option-filter-prop":"children","placeholder":"Código","disabled":_vm.disabledSomeFields}},_vm._l((_vm.countriesNotRepeatByName),function(country){return _c('a-select-option',{key:country._id,attrs:{"title":country.nameSpanish}},[_vm._v(" "+_vm._s(country.dialCode)+" "+_vm._s(country.nameSpanish)+" ")])}),1)],1)],1)],1),_c('a-form-item',{attrs:{"label":"Motivo del bloqueo"}},[_c('counter-text',{attrs:{"text":_vm.message,"maxLength":500}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'reason',
            {
              rules: [{ required: true, message: 'Este campo es requerido' }],
            } ]),expression:"[\n            'reason',\n            {\n              rules: [{ required: true, message: 'Este campo es requerido' }],\n            },\n          ]"}],staticClass:"position-relative",attrs:{"auto-size":{ minRows: 4, maxRows: 4 },"placeholder":"Escribe aquí...","maxLength":500},on:{"input":function($event){return _vm.handleSetMessage($event.target.value)}}})],1)],1)],1),_c('basic-footer',{attrs:{"ok-text":_vm.okText,"ok-loading":_vm.okButton.loading,"cancel-loading":_vm.cancelButton.loading,"ok-disabled":_vm.okButton.loading || _vm.okButton.disabled,"cancel-disabled":_vm.cancelButton.disabled,"cancelText":"Cancelar"},on:{"onOk":_vm.handleOk,"onCancel":_vm.handleCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }