import { render, staticRenderFns } from "./ModalTransferAgent.vue?vue&type=template&id=3b476771&scoped=true&"
import script from "./ModalTransferAgent.vue?vue&type=script&lang=js&"
export * from "./ModalTransferAgent.vue?vue&type=script&lang=js&"
import style0 from "./ModalTransferAgent.vue?vue&type=style&index=0&id=3b476771&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b476771",
  null
  
)

export default component.exports