var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-footer"},[_c('a-menu',{staticClass:"menu-footer__content",class:{
      'menu-footer__content--collapse': _vm.collapse,
      'menu-footer__content--no-collapse': !_vm.collapse,
    },attrs:{"mode":"vertical"},on:{"openChange":_vm.handleOpenChangePopup}},[_c('a-sub-menu',{key:"submenu-footer-first",staticClass:"menu-footer__submenu",attrs:{"placement":"rightBottom","popupClassName":_vm.popupClassNameFooter,"disabled":_vm.disabled},on:{"titleClick":_vm.handleGoToProfile}},[_c('menu-item-footer',{attrs:{"slot":"title","collapse":_vm.collapse,"title":_vm.names,"subtitle":_vm.email,"avatar":_vm.avatar},slot:"title"}),(_vm.showPopup)?[_c('a-menu-item',{key:"1",staticClass:"menu-footer__contain-summary",style:(("height: " + (_vm.profileStatus.action_when_select.includes('show-status-in-avatar')
              ? '348px'
              : '290px') + " ; padding: 0; margin-top: 0")),attrs:{"disabled":""}},[_c('summary-account',{attrs:{"names":_vm.names,"email":_vm.email,"username":_vm.username,"phone-number":_vm.phoneNumber,"avatar":_vm.avatar,"banner":_vm.banner,"type-user":_vm.typeUser,"company-name":_vm.companyName,"plan-name":_vm.planName,"timer":_vm.timerFormatted},on:{"onReconnect":_vm.handleReconnect}})],1),_c('a-menu-divider'),_c('a-menu-item',{key:"2",staticClass:"menu-footer__item",on:{"click":_vm.handleGoToProfile}},[_vm._v(" Mi perfil ")]),_c('a-menu-divider'),_c('a-sub-menu',{key:"3",staticClass:"menu-footer__submenu--states",attrs:{"title":"Cambiar mi estado a:","id":"states","popupClassName":_vm.popupClassNameStates}},_vm._l((_vm.profile_states_for_list),function(status){return _c('a-menu-item',{key:status._id,staticClass:"fix-center",staticStyle:{"display":"flex","flex-direction":"row","align-items":"center"},attrs:{"id":status.state},on:{"click":function($event){return _vm.handleSetStatus(status.state, status.action_when_select)}}},[_c('a-badge',{attrs:{"color":status.color,"text":status.title_for_footer_menu}})],1)}),1),_c('a-menu-divider'),_c('a-menu-item',{key:"3.1",staticClass:"menu-footer__item",on:{"click":_vm.handleGoToDownloads}},[_vm._v(" Mis Descargas ⬇️ ")]),_c('a-menu-divider'),(_vm.isAllowedFor(_vm.profile.type, ['admin', 'master_agent']))?[_c('a-menu-item',{key:"extra-4",staticClass:"menu-footer__item",on:{"click":_vm.handleGoToSpending}},[_vm._v(" Ver mi saldo 💰 ")]),_c('a-menu-divider')]:_vm._e(),(
            _vm.isAllowedFor(_vm.profile.type, ['admin', 'master_agent']) &&
            _vm.show_user_guide
          )?[_c('a-menu-item',{key:"5",staticClass:"menu-footer__item",staticStyle:{"height":"max-content"}},[_c('div',{staticClass:"menu-footer__content-item",on:{"click":_vm.handleGoToGuide}},[_vm._v(" Proceso de mi guía de usuario "),_c('a-progress',{staticClass:"menu-footer__progress",attrs:{"percent":_vm.percentage,"size":"small"}})],1)]),_c('a-menu-divider')]:_vm._e(),(_vm.submenu_chat_atc)?_c('a-menu-item',{staticClass:"menu-footer__item",on:{"click":_vm.handleGoToSupport}},[_vm._v(" Chatear con atención al cliente 👩🏻‍💻 ")]):_vm._e(),_c('a-menu-divider'),_c('a-menu-item',{key:"6",staticClass:"menu-footer__item",on:{"click":_vm.logout}},[_vm._v(" Cerrar mi sesión 👋 ")])]:_vm._e()],2),_c('a-menu-item',{key:"",staticStyle:{"height":"0px","margin":"0"}},[_c('a-icon',{attrs:{"type":""}}),_c('span')],1)],1),_c('modal-state',{attrs:{"type":_vm.modalState.type,"visible":_vm.modalState.visible,"timer":_vm.timerFormatted},on:{"onReconnect":_vm.handleReconnect}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }