var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.title,"afterClose":_vm.handleCancel,"body-style":_vm.bodyStyle,"centered":""},on:{"cancel":_vm.handleCancel}},[_c('custom-section-scroll',{attrs:{"master-styles":{ height: '40em' },"content-styles":{ padding: '24px' }}},[_c('p',[_vm._v(" Para usarlas, desde el módulo de Workspace, selecciona el ícono de "),_c('a-icon',{attrs:{"type":"thunderbolt"}}),_vm._v(" para añadir una respuesta rápida ")],1),_c('a-form',{attrs:{"layout":"vertical","form":_vm.form}},[_c('a-form-item',{attrs:{"label":"Título","extra":"Una palabra que mostrará rápidamente esta respuesta"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'title',
            {
              rules: [
                { required: true, message: 'Este campo es requerido' },
                { max: 34, message: '35 caracteres máximo' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                } ],
            } ]),expression:"[\n            'title',\n            {\n              rules: [\n                { required: true, message: 'Este campo es requerido' },\n                { max: 34, message: '35 caracteres máximo' },\n                {\n                  whitespace: true,\n                  message: 'No se admiten espacios en blanco.',\n                },\n              ],\n            },\n          ]"}],attrs:{"maxLength":35,"placeholder":"Escribe aquí..."}})],1),_c('div',{staticClass:"ant-col ant-form-item-label"},[_c('label',{staticClass:"ant-form-item-required"},[_vm._v("Mensaje "),(_vm.checkedMultimedia)?_c('a-tooltip',{attrs:{"placement":"right"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" Archivos aceptados: "),_c('br'),_vm._l((_vm.MULTIMEDIA_SUPPORT),function(multimedia,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(multimedia.title_plural)+": "+_vm._s(multimedia.formats.titles.join(', '))+" "),_c('br')])})],2),_c('a-icon',{attrs:{"type":"info-circle"}})],1):_vm._e()],1)]),(_vm.checkedMultimedia)?_c('a-form-item',{attrs:{"extra":"En algunos canales, el archivo se enviará en un mensaje separado."}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'picture',
            {
              rules: [
                {
                  required: _vm.isPictureRequired,
                  message: 'Este campo es requerido',
                } ],
            } ]),expression:"[\n            'picture',\n            {\n              rules: [\n                {\n                  required: isPictureRequired,\n                  message: 'Este campo es requerido',\n                },\n              ],\n            },\n          ]"}],staticClass:"drawer__uploader drawer-product__uploader",attrs:{"name":"picture","list-type":"picture-card","show-upload-list":false,"customRequest":_vm.uploadMedia,"before-upload":_vm.beforeUpload}},[_c('upload-content-preview',{attrs:{"loading":_vm.loading,"file-url":_vm.fileAttached.url,"file-caption":_vm.fileAttached.caption,"file-type":_vm.multimediaSelected.type}})],1)],1):_vm._e(),_c('a-form-item',{staticStyle:{"margin-bottom":"6px"}},[_c('counter-text',{attrs:{"text":_vm.message,"maxLength":980}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'body',
              {
                rules: [
                  {
                    required: !_vm.checkedMultimedia,
                    message: 'Este campo es requerido',
                  } ],
              } ]),expression:"[\n              'body',\n              {\n                rules: [\n                  {\n                    required: !checkedMultimedia,\n                    message: 'Este campo es requerido',\n                  },\n                ],\n              },\n            ]"}],staticClass:"position-relative",attrs:{"auto-size":{ minRows: 4, maxRows: 4 },"placeholder":"Escribe aquí...","maxLength":980},on:{"input":function($event){return _vm.handleSetMessage($event.target.value)}}})],1),_c('emoji-picker',{attrs:{"icon-styles":{
            fontSize: '16px',
            position: 'absolute',
            top: '8px',
            right: '8px',
          },"show-tooltip":false},on:{"onChangeEmoji":_vm.handleSetEmoji}})],1),_c('a-checkbox',{staticClass:"mrg-bottom-24",attrs:{"checked":_vm.checkedMultimedia},on:{"change":_vm.handleCheckMultimedia}},[_vm._v("Incluir multimedia en el mensaje")]),_c('a-form-item',{attrs:{"label":"Asignar a colas (Opcional)","extra":"¡RECUERDA! Esta respuesta rápida solo le aparecerá a los agentes asignados a la cola seleccionada"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'linesIds',
            {
              rules: [
                { required: false, message: 'Este campo es requerido' } ],
            } ]),expression:"[\n            'linesIds',\n            {\n              rules: [\n                { required: false, message: 'Este campo es requerido' },\n              ],\n            },\n          ]"}],attrs:{"mode":"multiple","placeholder":"Selecciona aquí..."}},_vm._l((_vm.lines),function(line){return _c('a-select-option',{key:line._id},[_vm._v(" "+_vm._s(line.name)+" ")])}),1)],1)],1)],1),_c('basic-footer',{attrs:{"ok-text":_vm.okText,"ok-loading":_vm.okButton.loading,"cancel-loading":_vm.cancelButton.loading,"ok-disabled":_vm.okButton.disabled,"cancel-disabled":_vm.cancelButton.disabled,"cancelText":"Cancelar"},on:{"onOk":_vm.handleOk,"onCancel":_vm.handleCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }