var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":"Páginas asociadas a tu cuenta","width":"min-content","centered":"","maskClosable":false,"bodyStyle":_vm.styleModalSelected,"afterClose":_vm.afterCloseModalSelected},on:{"cancel":_vm.handleCancelSelected}},[_c('custom-section-scroll',{attrs:{"master-styles":{
      height: '385px',
    },"content-styles":{ padding: '12px 24px' }}},[_c('p',{staticClass:"body-2"},[_vm._v("Selecciona una de tus páginas para poder continuar.")]),_c('a-radio-group',{staticStyle:{"width":"100%"},model:{value:(_vm.pageSelected.idFacebookPage),callback:function ($$v) {_vm.$set(_vm.pageSelected, "idFacebookPage", $$v)},expression:"pageSelected.idFacebookPage"}},_vm._l((_vm.listPages),function(page){return _c('a-radio',{key:page.id,style:(_vm.radioStyle),attrs:{"value":page.id}},[_c('span',{staticClass:"card-channel-page",class:{
            'card-channel-page-selected':
              page.id === _vm.pageSelected.idFacebookPage,
            'card-channel-page-no-selected':
              page.id !== _vm.pageSelected.idFacebookPage,
          }},[_c('img',{staticStyle:{"border-radius":"4px"},attrs:{"src":page.picture.data.url,"alt":page.name,"height":"32px","width":"32px"}}),_c('h6',{staticClass:"heading-h6 name-page"},[_vm._v(" "+_vm._s(page.name)+" ")])])])}),1)],1),_c('template',{slot:"footer"},[_c('a-button',{on:{"click":_vm.handleCancelSelected}},[_vm._v("Cancelar")]),_c('a-button',{attrs:{"type":"primary","disabled":_vm.pageSelected.idFacebookPage ? false : true,"loading":_vm.loadBtnConected},on:{"click":_vm.handleSelected}},[_vm._v("Conectar")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }