var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[(_vm.request && _vm.alertObj)?_c('a-alert',{staticClass:"form__alert",attrs:{"type":_vm.alertObj.type}},[_c('p',{staticClass:"body-1 mrg-bottom-0 alert_description",attrs:{"slot":"description"},slot:"description"},[_vm._v(" "+_vm._s(_vm.alertObj.description)+" ")])]):_vm._e(),_c('a-row',{staticClass:"contain-avatar",attrs:{"type":"flex","align":"middle"}},[(_vm.request && _vm.broadcast.editable)?_c('a-upload',{staticClass:"upload",attrs:{"name":"avatar","multiple":false,"show-upload-list":false,"before-upload":_vm.beforeUpload,"customRequest":_vm.selfUpload}},[_c('img',{staticClass:"upload_img",attrs:{"src":_vm.avatar,"height":"64px","width":"64px","alt":"Avatar"}}),_c('div',{staticClass:"overlay",class:{ 'opacity-1': _vm.loading }},[(!_vm.loading)?_c('a-icon',{staticStyle:{"font-size":"20px","color":"white"},attrs:{"type":"edit"}}):_c('a-icon',{staticStyle:{"font-size":"20px","color":"white"},attrs:{"type":"loading"}})],1)]):_c('img',{staticClass:"upload_img",attrs:{"src":_vm.avatar,"height":"64px","width":"64px","alt":"Avatar"}}),_c('span',{staticClass:"text"},[(_vm.request)?[_c('p',{staticClass:"heading-h8 mrg-bottom-0"},[_vm._v("Sube aquí tu nuevo avatar")]),_c('p',{staticClass:"body-1 mrg-bottom-0"},[_vm._v(" El tamaño máximo permitido es de 5MB ")]),_c('p',{staticClass:"body-1 mrg-bottom-0"},[_vm._v(" Las dimensiones ideales son 640px x 640px ")])]:_vm._e()],2)],1),_c('a-form-item',{attrs:{"label":"Descripción"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'whatsapp_description',
          {
            rules: [
              {
                required: false,
                message: 'Este campo es requerido',
              },
              {
                whitespace: true,
                message: 'No se aceptan espacios vacios',
              } ],
          } ]),expression:"[\n          'whatsapp_description',\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Este campo es requerido',\n              },\n              {\n                whitespace: true,\n                message: 'No se aceptan espacios vacios',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí...","auto-size":{ minRows: 2, maxRows: 4 },"maxLength":_vm.maxDescription,"disabled":!_vm.broadcast.editable},on:{"input":_vm.handleSetCurrentDescription}}),_c('span',{staticClass:"counter"},[_vm._v(_vm._s(_vm.currentDescription ? _vm.currentDescription.length : 0)+"/"+_vm._s(_vm.maxDescription))])],1),_c('a-form-item',{attrs:{"label":"Dirección"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'whatsapp_address',
          {
            rules: [
              {
                required: false,
                message: 'Por favor completa este campo',
              } ],
          } ]),expression:"[\n          'whatsapp_address',\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Por favor completa este campo',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí...","disabled":!_vm.broadcast.editable},on:{"input":function (e) { return _vm.handleEmitChange({ property: 'address', value: e.target.value }); }}})],1),_c('a-row',{attrs:{"gutter":[12]}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"País"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'whatsapp_country',
              {
                rules: [
                  {
                    required: false,
                    message: 'Por favor completa este campo',
                  } ],
              } ]),expression:"[\n              'whatsapp_country',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'Por favor completa este campo',\n                  },\n                ],\n              },\n            ]"}],attrs:{"show-search":"","placeholder":"Selecciona","disabled":!_vm.broadcast.editable}},_vm._l((_vm.countriesNotRepeatByName),function(country){return _c('a-select-option',{key:country.nameSpanish},[_vm._v(" "+_vm._s(country.nameSpanish)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Ciudad"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'whatsapp_city',
              {
                rules: [
                  {
                    required: true,
                    message: 'Por favor completa este campo',
                  } ],
              } ]),expression:"[\n              'whatsapp_city',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Por favor completa este campo',\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"Escribe aquí...","disabled":!_vm.broadcast.editable},on:{"input":function (e) { return _vm.handleEmitChange({ property: 'city', value: e.target.value }); }}})],1)],1)],1),_c('a-form-item',{attrs:{"label":"Código postal"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'whatsapp_zipcode',
          {
            rules: [
              {
                required: false,
                message: 'Por favor completa este campo',
              } ],
          } ]),expression:"[\n          'whatsapp_zipcode',\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Por favor completa este campo',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí...","disabled":!_vm.broadcast.editable},on:{"input":function (e) { return _vm.handleEmitChange({ property: 'zipcode', value: e.target.value }); }}})],1),(_vm.broadcast.email)?_c('a-form-item',{attrs:{"label":"Correo electrónico"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'whatsapp_email',
          {
            rules: [
              {
                required: false,
                message: 'Este campo es requerido',
              },
              { type: 'email', message: 'No es un email válido' },
              {
                whitespace: true,
                message: 'No se aceptan espacios vacios',
              } ],
          } ]),expression:"[\n          'whatsapp_email',\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Este campo es requerido',\n              },\n              { type: 'email', message: 'No es un email válido' },\n              {\n                whitespace: true,\n                message: 'No se aceptan espacios vacios',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí...","disabled":!_vm.broadcast.editable},on:{"input":function (e) { return _vm.handleEmitChange({ property: 'email', value: e.target.value }); }}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"Página web"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'whatsapp_website',
          {
            rules: [
              {
                required: false,
                message: 'Por favor completa este campo',
              } ],
          } ]),expression:"[\n          'whatsapp_website',\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Por favor completa este campo',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí...","disabled":!_vm.broadcast.editable},on:{"input":function (e) { return _vm.handleEmitChange({ property: 'website', value: e.target.value }); }}})],1),_c('a-form-item',{attrs:{"label":"Información"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'whatsapp_about',
          {
            rules: [
              {
                required: false,
                message: 'Este campo es requerido',
              },
              {
                whitespace: true,
                message: 'No se aceptan espacios vacios',
              } ],
          } ]),expression:"[\n          'whatsapp_about',\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Este campo es requerido',\n              },\n              {\n                whitespace: true,\n                message: 'No se aceptan espacios vacios',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí...","auto-size":{ minRows: 2, maxRows: 4 },"maxLength":_vm.maxAbout,"disabled":!_vm.broadcast.editable},on:{"input":_vm.handleSetCurrentAbout}}),_c('span',{staticClass:"counter"},[_vm._v(_vm._s(_vm.currentAbout ? _vm.currentAbout.length : 0)+"/"+_vm._s(_vm.maxAbout))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }