var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Mensaje de opción múltiple"}},[_c('p',{staticClass:"help text--left mrg-bottom-8"},[_vm._v(" Recuerda que sera un mensaje de introducción para tus usuarios. ")]),_c('counter-text',{attrs:{"maxLength":_vm.validations.maxLengthMessage,"text":_vm.form.getFieldValue('optionsContent')
          ? _vm.form.getFieldValue('optionsContent')
          : ''}},[_c('a-mentions',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'optionsContent',
          {
            rules: [
              {
                required: true,
                message: 'Por favor rellene el campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              } ],
          } ]),expression:"[\n          'optionsContent',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor rellene el campo',\n              },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],ref:"optionsContent",staticClass:"text--left",attrs:{"rows":"3","placeholder":"Escribe aquí...","maxLength":_vm.validations.maxLengthMessage,"prefix":_vm.PREFIX_VARS,"notFoundContent":_vm.notFoundVars,"placement":"bottom","disabled":_vm.onlyRead,"filterOption":_vm.filterOption},on:{"change":function($event){return _vm.setIsSavedItem(false)}}},_vm._l((_vm.simpleVars),function(simpleVar){return _c('a-mentions-option',{key:simpleVar._id,attrs:{"value":simpleVar.name}},[_vm._v(" "+_vm._s(simpleVar.name))])}),1)],1),_c('menu-options-content',{attrs:{"show-wait":false,"disabled-emoji":_vm.onlyRead},on:{"onChangeEmoji":function (emoji) { return _vm.handleSetEmoji(emoji, 'optionsContent'); },"onOpenVars":function($event){return _vm.handleOpenVars('optionsContent')}}})],1),_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Mensaje por respuesta incorrecta"}},[_c('p',{staticClass:"help",staticStyle:{"margin-bottom":"8px","text-align":"left"}},[_vm._v(" Recuerda este mensaje se mostrará al cliente cuando se ingrese una opción diferente a las mostradas ")]),_c('counter-text',{attrs:{"maxLength":_vm.validations.maxLengthErrorMessage,"text":_vm.form.getFieldValue('errorMessage')
          ? _vm.form.getFieldValue('errorMessage')
          : ''}},[_c('a-mentions',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'errorMessage',
          {
            rules: [
              {
                required: true,
                message: 'Por favor rellene el campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              } ],
          } ]),expression:"[\n          'errorMessage',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor rellene el campo',\n              },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],ref:"errorMessage",staticClass:"text--left",attrs:{"rows":"3","placeholder":"Escribe aquí...","maxLength":_vm.validations.maxLengthErrorMessage,"prefix":_vm.PREFIX_VARS,"notFoundContent":_vm.notFoundVars,"placement":"bottom","disabled":_vm.onlyRead,"filterOption":_vm.filterOption},on:{"change":function($event){return _vm.setIsSavedItem(false)}}},_vm._l((_vm.simpleVars),function(simpleVar){return _c('a-mentions-option',{key:simpleVar._id,attrs:{"value":simpleVar.name}},[_vm._v(" "+_vm._s(simpleVar.name))])}),1)],1),_c('menu-options-content',{attrs:{"show-wait":false,"disabled-emoji":_vm.onlyRead},on:{"onChangeEmoji":function (emoji) { return _vm.handleSetEmoji(emoji, 'errorMessage'); },"onOpenVars":function($event){return _vm.handleOpenVars('errorMessage')}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }