var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.title,"afterClose":_vm.handleCancel,"centered":""},on:{"cancel":_vm.handleCancel}},[_c('p',[_vm._v(" Recuerda ser precisa y concisa con el nombre del campo y de las opciones para evitar confusiones con tus agentes. ")]),_c('p',{staticClass:"regular-14 modal__label mrg-bottom-8"},[_vm._v("Avatar de empresa")]),_c('a-row',{staticClass:"flex-nowrap mrg-bottom-20",attrs:{"type":"flex","align":"middle"}},[_c('uploader-avatar',{attrs:{"width":"60px","height":"60px","color-default":"red","icon-default":"company","default-avatar":_vm.avatar.avatar},on:{"onChange":_vm.onChangeAvatar}}),_c('div',{staticClass:"mrg-left-12"},[_c('p',{staticClass:"mrg-bottom-0"},[_vm._v(" El tamaño máximo permitido es de "+_vm._s(_vm.max_size_to_upload_avatar_company)+"MB. "),_c('br'),_vm._v(" Las dimensiones ideales son 500px x 500px ")])])],1),_c('a-form',{attrs:{"layout":"vertical","form":_vm.form}},[_c('a-form-item',{attrs:{"label":"Nombre de empresa"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [
              { required: true, message: 'Este campo es requerido' },
              { max: 35, message: '35 caracteres máximo' },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              } ],
          } ]),expression:"[\n          'name',\n          {\n            rules: [\n              { required: true, message: 'Este campo es requerido' },\n              { max: 35, message: '35 caracteres máximo' },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí...","maxLength":50}})],1),_c('a-form-item',{attrs:{"label":"País"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'country',
          {
            rules: [
              { required: true, message: 'Por favor rellena el campo' } ],
          } ]),expression:"[\n          'country',\n          {\n            rules: [\n              { required: true, message: 'Por favor rellena el campo' },\n            ],\n          },\n        ]"}],attrs:{"show-search":"","placeholder":"Selecciona aquí"},on:{"popupScroll":_vm.handleLoadMoreCountries,"search":_vm.handleSearchCountry}},_vm._l((_vm.countriesNotRepeatByName),function(localCountry){return _c('a-select-option',{key:localCountry.nameSpanish},[_vm._v(" "+_vm._s(localCountry.nameSpanish)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"Tipo de operación"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'operation',
          {
            rules: [
              { required: true, message: 'Por favor rellena el campo' } ],
          } ]),expression:"[\n          'operation',\n          {\n            rules: [\n              { required: true, message: 'Por favor rellena el campo' },\n            ],\n          },\n        ]"}],attrs:{"mode":"multiple","placeholder":"Selecciona aquí"}},_vm._l((_vm.operations),function(operation){return _c('a-select-option',{key:operation.key},[_vm._v(" "+_vm._s(operation.value)+" ")])}),1)],1)],1),_c('basic-footer',{attrs:{"ok-text":_vm.okText,"ok-loading":_vm.okButton.loading,"cancel-loading":_vm.cancelButton.loading,"ok-disabled":_vm.okButton.disabled,"cancel-disabled":_vm.cancelButton.disabled,"cancelText":"Cancelar"},on:{"onOk":_vm.handleOk,"onCancel":_vm.handleCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }