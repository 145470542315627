var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"password-form"},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"Nueva contraseña","has-feedback":""}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {
            rules: [
              {
                required: true,
                message: 'Este campo es requerido.',
              },
              {
                min: 8,
                message: '8 caracteres a más.',
              },
              {
                pattern: /[A-Z]/g,
                message: '1 carácter en mayúscula.',
              },
              {
                pattern: /[a-z]/g,
                message: '1 carácter en minúscula.',
              },
              {
                pattern: /[0-9]/g,
                message: '1 número.',
              },
              {
                pattern: /[^A-Za-z0-9]/g,
                message: '1 carácter especial',
              },
              {
                validator: _vm.validateToNextPassword,
              } ],
          } ]),expression:"[\n          'password',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Este campo es requerido.',\n              },\n              {\n                min: 8,\n                message: '8 caracteres a más.',\n              },\n              {\n                pattern: /[A-Z]/g,\n                message: '1 carácter en mayúscula.',\n              },\n              {\n                pattern: /[a-z]/g,\n                message: '1 carácter en minúscula.',\n              },\n              {\n                pattern: /[0-9]/g,\n                message: '1 número.',\n              },\n              {\n                pattern: /[^A-Za-z0-9]/g,\n                message: '1 carácter especial',\n              },\n              {\n                validator: validateToNextPassword,\n              },\n            ],\n          },\n        ]"}],attrs:{"size":_vm.inputs_size,"placeholder":"Escribir nueva contraseña"}})],1),_c('a-form-item',{attrs:{"label":"Confirmar nueva contraseña","has-feedback":""}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'confirm',
          {
            rules: [
              {
                required: true,
                message: 'Por favor confirme su contraseña',
              },
              {
                validator: _vm.compareToFirstPassword,
              } ],
          } ]),expression:"[\n          'confirm',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor confirme su contraseña',\n              },\n              {\n                validator: compareToFirstPassword,\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribir nueva contraseña","size":_vm.inputs_size},on:{"blur":_vm.handleConfirmBlur,"input":_vm.onInputConfirm}})],1),_c('a-button',{staticStyle:{"width":"100%","margin-top":"4%"},attrs:{"type":"primary","html-type":"submit","loading":_vm.loadingBtn,"disabled":_vm.disabledBtn,"size":_vm.buttons_size}},[_vm._v(_vm._s(_vm.nameButton))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }