var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.title,"afterClose":_vm.handleCancel,"body-style":{ paddingBottom: '8px' },"centered":""},on:{"cancel":_vm.handleCancel}},[_c('p',[_vm._v(" Crea etiquetas, podrán ser asignadas a las conversaciones y así poder realizar reportes. ")]),_c('a-form',{staticClass:"modal-form",attrs:{"layout":"vertical","form":_vm.form},on:{"submit":_vm.handleOk}},[_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Nombre de etiqueta"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'title',
          {
            rules: [
              { required: true, message: 'Por favor rellena el campo' } ],
          } ]),expression:"[\n          'title',\n          {\n            rules: [\n              { required: true, message: 'Por favor rellena el campo' },\n            ],\n          },\n        ]"}],attrs:{"maxLength":50,"placeholder":"Escribe aquí..."}})],1),_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Color"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'color',
          { initialValue: _vm.colorDefault },
          {
            rules: [
              {
                required: true,
                message: 'Por favor seleccione un color',
              } ],
          } ]),expression:"[\n          'color',\n          { initialValue: colorDefault },\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Por favor seleccione un color',\n              },\n            ],\n          },\n        ]"}],staticClass:"form__select-color",attrs:{"placeholder":"Color"}},_vm._l((_vm.COLORS_TAGS),function(color){return _c('a-select-option',{key:("" + color)},[_c('ColorPick',{attrs:{"color":color,"size":"bold","pickStyles":{ width: '80px' }}})],1)}),1)],1),_c('h6',{staticClass:"semibold-14"},[_vm._v("Asignación de colas")]),_c('a-radio-group',{staticClass:"modal__radios",model:{value:(_vm.assignAction),callback:function ($$v) {_vm.assignAction=$$v},expression:"assignAction"}},[_c('a-radio',{style:(_vm.radioStyle),attrs:{"value":"allLines"}},[_c('span',{staticClass:"form__radio__label"},[_vm._v(" Asignar a todas las colas actuales y a las colas futuras. ")]),_c('p',{staticClass:"form__radio__description"},[_vm._v(" Esta etiqueta aparecerá para todos los tickets. ")])]),_c('a-radio',{style:(_vm.radioStyle),attrs:{"value":"specific"}},[_c('span',{staticClass:"form__radio__label"},[_vm._v(" Asignar a colas (Opcional) ")]),_c('a-form-item',{staticClass:"form__radio__form-item"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'linesIds',
              {
                rules: [
                  { required: false, message: 'Este campo es requerido' } ],
              } ]),expression:"[\n              'linesIds',\n              {\n                rules: [\n                  { required: false, message: 'Este campo es requerido' },\n                ],\n              },\n            ]"}],attrs:{"mode":"multiple","placeholder":"Selecciona aquí...","disabled":_vm.assignAction !== 'specific'}},_vm._l((_vm.lines),function(line){return _c('a-select-option',{key:line._id},[_vm._v(" "+_vm._s(line.name)+" ")])}),1),_c('span',{staticClass:"text--pre-wrap",attrs:{"slot":"extra"},slot:"extra"},[_vm._v("Esta etiqueta solo le aparecerá a los tickets asignados a la cola seleccionada")])],1)],1)],1)],1),_c('basic-footer',{attrs:{"ok-text":_vm.okText,"ok-loading":_vm.okButton.loading,"cancel-loading":_vm.cancelButton.loading,"ok-disabled":_vm.hasErrors(_vm.form.getFieldsError()),"cancel-disabled":_vm.cancelButton.disabled,"cancelText":"Cancelar"},on:{"onOk":_vm.handleOk,"onCancel":_vm.handleCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }