<template>
  <a-modal
    :visible="visible"
    :bodyStyle="bodyStyle"
    :width="widthModal"
    centered
    @cancel="$emit('onClose')"
    :footer="null"
  >
    <a-row type="flex" :justify="justify">
      <!--Datos de plantilla enviada-->
      <div v-if="dataSource.template">
        <h5 class="semibold-20">Previsualización de plantilla</h5>
        <p class="regular-14 mrg-bottom-0">
          <a-icon type="info-circle" />
          Esta previsualización es referencial
        </p>
        <a-divider class="modal__divider" />
        <div class="modal__item">
          <h6 class="semibold-16 mrg-bottom-8 modal__item__title">
            Tipo de plantilla
          </h6>
          <p class="regular-14">{{ mediaType }}</p>
        </div>
        <div class="modal__item">
          <h6 class="semibold-16 mrg-bottom-8 modal__item__title">Plantilla</h6>
          <p class="regular-14">{{ dataSource.friendlyName }}</p>
        </div>
      </div>
      <!--Título para SMS-->
      <h5
        v-if="dataSource && dataSource.channel_company.channel === 'sms'"
        class="semibold-20 mrg-bottom-20"
      >
        Previsualización de mensaje SMS
      </h5>
      <mobile-wsp
        :message="dataSource.body"
        :media-type="mediaTypeToPreview"
        :media-url="dataSource.url || null"
        :file-name="dataSource.caption"
        :channel="channel"
        :buttons="dataSource.buttons || []"
      />
    </a-row>
    <basic-footer
      :showOk="false"
      cancelText="Cerrar previsualización"
      @onCancel="$emit('onClose')"
      justify="justify-content--center"
    />
  </a-modal>
</template>

<script>
import MobileWsp from '@/app/strategies/components/MobileWsp'
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'
import { TYPE_TEMPLATES_FOR_LIST } from '@/app/channels/utils/componentsDataTemplates'

export default {
  name: 'ModalPreview',
  components: {
    MobileWsp,
    BasicFooter,
  },
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataSource: {
      type: Object,
      required: false,
    },
    channel: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    bodyStyle: {
      padding: '60px 60px 80px',
    },
  }),
  computed: {
    /**
     * @return {String} tipo de plantilla para mostrar en el modal
     */
    mediaType() {
      return (
        this.dataSource &&
        this.transformTypeAttachment(this.dataSource.typeAttachment || null)
      )
    },
    /**
     * @return {String} media para mostrar en la previsualización de whatsapp
     */
    mediaTypeToPreview() {
      return (
        this.dataSource.typeAttachment &&
        this.dataSource.typeAttachment.toLowerCase()
      )
    },
    /**
     * @return {String} ancho del modal
     */
    widthModal() {
      if (this.dataSource.template) return '52em'
      else return '40em'
    },
    /**
     * @return {String} justify para el modal
     */
    justify() {
      if (this.dataSource.template) return 'space-between'
      else return 'center'
    },
  },
  methods: {
    /**
     * Transforma el valor de la propiedad "typeAttachment"
     * @param {String} typeAttachment
     * @return {String}
     */
    transformTypeAttachment(typeAttachment) {
      const typeTemplate = TYPE_TEMPLATES_FOR_LIST.find(
        (typeTemplateForList) =>
          typeTemplateForList.value === (typeAttachment || 'TEXT')
      )
      return typeTemplate.title
    },
  },
}
</script>

<style lang="sass" scoped>
.modal__divider
  margin: 12px 0px
.modal__item
  margin-bottom: 20px
  color: $gray_8
  &__title
    color: inherit
</style>
