import { render, staticRenderFns } from "./ModalPreviewChatbot.vue?vue&type=template&id=5a3558f0&scoped=true&"
import script from "./ModalPreviewChatbot.vue?vue&type=script&lang=js&"
export * from "./ModalPreviewChatbot.vue?vue&type=script&lang=js&"
import style0 from "./ModalPreviewChatbot.vue?vue&type=style&index=0&id=5a3558f0&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a3558f0",
  null
  
)

export default component.exports